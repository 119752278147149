body {
  padding: 0;
  margin: 0;
  overflow: hidden;
}

#root {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}

.vsm-control-dock {
  padding: 15px
}

.vsm-control-dock > *:not(:last-child) {
    margin-bottom: 15px;
}

.vsm-button {
  width: 40px !important;
  height: 40px !important;
}
